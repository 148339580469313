.page{
	transition: all .4s;
	position: absolute;
	width: 100%;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	height: 100vh;
	overflow-x: hidden;
	will-change: transform;
	will-change: opacity;
	will-change: filter;
	will-change: z-index;
} 

.page-transition-slide-in .page-enter{
	transform: translate3d(100%, 0, 0);
	z-index: 10;
}

.page-transition-slide-in .page-enter-active{
	transform: translate3d(0,0,0);
}

.page-transition-slide-in .page-exit{
	filter: brightness(50%);
}

.page-transition-slide-out .page-enter{
	transition: filter 0s;
	filter: brightness(50%);
}

.page-transition-slide-out .page-enter-active{
	transition: filter 0.4s;
	filter: brightness(100%)
}

.page-transition-slide-out .page-exit{
	transform: translate3d(100%, 0, 0);
}

.page-transition-fade-in .page-enter{
	opacity: 0;
	z-index: 10;
	transition: opacity 0s;
}

.page-transition-fade-in .page-enter-active{
	opacity: 1;
	transition: opacity .4s;
}

.page-transition-fade-in .page-exit{
	opacity: 0;	
}

.page-transition-fade-out .page-enter{
	opacity: 0;
}

.page-transition-fade-out .page-enter-active{
	opacity: 1;
}

.page-transition-fade-out .page-exit{
	opacity: 0;
}