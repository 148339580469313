.card{
	overflow: hidden;
}

.card .cardGradient{
	opacity: 0;
	z-index: 2;
}

.card:hover .cardGradient{
	opacity: 0.98;
}

.card .cardExtraInfo{
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;
	line-height: 1.5em;
	overflow: hidden;
}

.card:hover .cardExtraInfo{
	opacity: 1;
	z-index: 3;
}

.cardExtraInfoHidden{
	display: none;
}