.topBar{
	position: sticky;
	z-index: 2;
	display: flex;
}

.timeBar{
	position: sticky;
	z-index: 1;
	padding: 10px;
}