body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	color: steelblue;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.titlebar_titleBar__25HYg{ 
	display: -webkit-flex; 
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	position: -webkit-sticky;
	position: sticky;
	top: 0; 
	z-index: 2; 
	width: 100%; 
	transition: background-color 0.3s ease-in-out;
}
.loadingIcon{
	-webkit-animation-name: fadein;
	        animation-name: fadein;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-direction: normal;
	        animation-direction: normal;
	-webkit-animation-iteration-count: 1;
	        animation-iteration-count: 1;
	opacity: 1;
}

@-webkit-keyframes fadein{
	0% { opacity: 0; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes fadein{
	0% { opacity: 0; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
.card_card__33cE3{
	overflow: hidden;
}

.card_card__33cE3 .card_cardGradient__16Du-{
	opacity: 0;
	z-index: 2;
}

.card_card__33cE3:hover .card_cardGradient__16Du-{
	opacity: 0.98;
}

.card_card__33cE3 .card_cardExtraInfo__3EOuy{
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: 1.5em;
	line-height: 1.5em;
	overflow: hidden;
}

.card_card__33cE3:hover .card_cardExtraInfo__3EOuy{
	opacity: 1;
	z-index: 3;
}

.card_cardExtraInfoHidden__1CORE{
	display: none;
}
.search_search__2gkNn{ 
	width: calc(100% - 20px);
	padding: 10px;
	position: -webkit-sticky;
	position: sticky; 
	z-index: 2;
}
.page{
	transition: all .4s;
	position: absolute;
	width: 100%;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	height: 100vh;
	overflow-x: hidden;
	will-change: transform;
	will-change: opacity;
	will-change: filter;
	will-change: z-index;
} 

.page-transition-slide-in .page-enter{
	-webkit-transform: translate3d(100%, 0, 0);
	        transform: translate3d(100%, 0, 0);
	z-index: 10;
}

.page-transition-slide-in .page-enter-active{
	-webkit-transform: translate3d(0,0,0);
	        transform: translate3d(0,0,0);
}

.page-transition-slide-in .page-exit{
	-webkit-filter: brightness(50%);
	        filter: brightness(50%);
}

.page-transition-slide-out .page-enter{
	transition: -webkit-filter 0s;
	transition: filter 0s;
	transition: filter 0s, -webkit-filter 0s;
	-webkit-filter: brightness(50%);
	        filter: brightness(50%);
}

.page-transition-slide-out .page-enter-active{
	transition: -webkit-filter 0.4s;
	transition: filter 0.4s;
	transition: filter 0.4s, -webkit-filter 0.4s;
	-webkit-filter: brightness(100%);
	        filter: brightness(100%)
}

.page-transition-slide-out .page-exit{
	-webkit-transform: translate3d(100%, 0, 0);
	        transform: translate3d(100%, 0, 0);
}

.page-transition-fade-in .page-enter{
	opacity: 0;
	z-index: 10;
	transition: opacity 0s;
}

.page-transition-fade-in .page-enter-active{
	opacity: 1;
	transition: opacity .4s;
}

.page-transition-fade-in .page-exit{
	opacity: 0;	
}

.page-transition-fade-out .page-enter{
	opacity: 0;
}

.page-transition-fade-out .page-enter-active{
	opacity: 1;
}

.page-transition-fade-out .page-exit{
	opacity: 0;
}
.agenda_topBar__9OVzZ{
	position: -webkit-sticky;
	position: sticky;
	z-index: 2;
	display: -webkit-flex;
	display: flex;
}

.agenda_timeBar__2JiWU{
	position: -webkit-sticky;
	position: sticky;
	z-index: 1;
	padding: 10px;
}
