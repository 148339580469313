.loadingIcon{
	animation-name: fadein;
	animation-duration: 2s;
	animation-direction: normal;
	animation-iteration-count: 1;
	opacity: 1;
}

@keyframes fadein{
	0% { opacity: 0; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}